/* Markup: Skweb (skt.pw) */
@import "vars";
@import "mixins";
.page-preloader{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1050;
	overflow:hidden;
	//display:none;
	transition:all 1s ease-out;
	&.readyBottom{
		top:auto;
		bottom:0;
	}
	.preloader-holder {
		background:#1A1A1A;
		position:absolute;
		top:0;
		width:100%;
		height:100vh;
		overflow:hidden;
		left:0;
	}
	.loader-circle{
		position: absolute;
		top:50%;
		left:50%;
		margin-left:-35px;
		margin-top:-35px;
		color: #fff;
		transition: 0.5s;
		width:70px;
		height:70px;
		border-radius:50%;
		border:2px solid #505050;
		z-index:3;
		&:before {
			@include after-box;
			position: absolute;
			top:-3px;
			left:-3px;
			bottom:-3px;
			right:-3px;
			border-radius: 50%;
			border: 4px solid transparent;
			border-top-color: $redColor;
			animation: spin 1s linear infinite;
		}
	}
	.loader-content{
		z-index:10;
		left:0;
		position:absolute;
		top:0;
		height:100%;
		padding-top:5vh;
		width:100%;
		@media #{$media-xs}{
			padding-top:4vh;
		}
	}
	.logo{
		display:block;
		max-width:145px;
		color:#fff;
		margin-top:18px;
		@media #{$media-xs}{
			margin-top:-10px;
			margin-left:-1vw;
		}
		span{
			font-size:1.8vw;
			font-weight:300;
			display:block;
			@media #{$media-xxlg}{
				font-size:30px;
			}
			@media #{$media-xs}{
				font-size:7vw;
				line-height:7vw;
			}
			b{
				font-weight:bold;
				@media #{$media-xs}{
					display:block;
				}
			}
		}
		img{
			display:block;
			margin-bottom:-3px;
			@media #{$media-xs}{
				margin-top:0;
				margin-bottom:2px;
			}
		}

	}
	.loader-text {
		position:absolute;
		color:#ffffff;
		z-index:3;
		bottom:10vh;
		margin-left:30px;
		font-size:4vw;
		font-weight:normal;
		font-family:Arial, serif;
		@media #{$media-md}{
			margin-left:0;
		}
		@media #{$media-xs}{
			margin-left:0;
		}
		em{
			font-style:normal;
			animation: opa 2s ease infinite;
			&:nth-child(2){animation-delay: 0.1s}
			&:nth-child(3){animation-delay: 0.2s}
			&:nth-child(4){animation-delay: 0.3s}
			&:nth-child(5){animation-delay: 0.4s}
			&:nth-child(6){animation-delay: 0.5s}
			&:nth-child(7){animation-delay: 0.6s}
			&:nth-child(8){animation-delay: 0.7s}
		}
	}

}
body.loaded-page{
	.page-preloader{
		height:0;
	}
}
@keyframes opa {
	0%{
		opacity:1;
	}
	50%{
		opacity:0.2;
	}
	100%{
		opacity:1;
	}
}
@keyframes spin {
	0%{
		transform: rotate(0deg);
	}
	100%{
		transform: rotate(360deg);
	}
}






.fake-cursor {
	position: fixed;
	width: 30px;
	height: 30px;
	margin-top: -5px;
	margin-left: -5px;
	border-radius: 100%;
	transition: 0.1s cubic-bezier(0.75, -0.27, 0.3, 1.33) background-color, 0.1s ease border-color;
	user-select: none;
	pointer-events: none;
	z-index: 10000;
	top: 0;
	left: 0;
	transform-origin:0 0;
	pointer-events: none;
	//transform:matrix(1, 0, 0, 1, 1282, 188) !important;
	@media #{$media-xs}{
		display: none;
	}
	.point {
		background:rgba(204, 204, 204, 0.51);
		position: absolute;
		top: 50%;
		left: 50%;
		width:26px;
		height:26px;
		margin-top: -13px;
		margin-left: -13px;
		border-radius: 50%;
		transition: all 0.3s ease;
		display:none;
		&:before {
			@include after-box;
			position:absolute;
			top:-30px;
			left:-30px;
			right:-30px;
			bottom:-30px;
			border-radius:50%;
			transition:all 0.3s ease;
			transform:scale(0);
			border:1px solid rgba(255,255,255,0.5);
		}
	}
	.arrows{
		position:absolute;
		top:50%;
		left:50%;
		margin-left:-53px;
		margin-top:-53px;
		width:106px;
		height:106px;
		border:1px solid #fff;
		background:$redColor;
		border-radius:50%;
		opacity:0;
		transform:scale(0);
		transition:all 0.3s ease;
		display:flex;
		flex-direction:row;
		flex-wrap:nowrap;
		justify-content:center;
		align-items:center;
		color:#fff;
		font-size:14px;
		font-weight:500;
		i {

			display:inline-block;
			opacity:0;
			transition:all 0.5s ease;
			transform:translateX(-5px);
			&:before{
				@include after-box;
				display:inline-block;
				border:solid #fff;
				border-width:0 1px 1px 0;
				padding:4px;
				transform:rotate(-45deg);
			}
			&.left{
				transform:translateX(5px);
				&:before {
					transform:rotate(135deg);
				}
			}
		}
		.txt{
			padding: 0 5px;
		}

	}



	&.can-drag{
		.point{
			transform:scale(0);
		}
		.arrows{
			opacity: 1;
			transform:scale(1);
			.left,
			.right {
				opacity:1;
				transform:translateX(0);
			}
		}
	}




}

body.loaded-page{
	.page-preloader{
		.logo-wrap{
			filter: blur(15px);
			opacity:0.3;
			transform:translateY(-50px);
			transition:all 1.5s ease;
			&:before {
				transform:translateY(-50%);
				transition-delay:0s;
			}
			//height:0;
		}
	}
}


/* JavaScript Turned Off */
.no-js #page-preloader {
	display: none;
}
