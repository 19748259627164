// px to rem

@mixin size($width,$height: $width) {
  width: $width;
  height: $height;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}
@mixin full-height-abs (){
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}
@mixin full-height-rel (){
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

@mixin after-fix{
  &:after{
    content:"";
    display:block;
    clear:both;
  }
}
@mixin after-box{
  content:"";
  display:block;
  clear:both;
}
// Media Queries
$media-xs: "(max-width : " + ($sm - 1) + ")";
$media-xs-sm: "(max-width : " + ($md - 1) + ")";
$media-sm: "(min-width:" + $sm +") and (max-width:" + ($md - 1) + ")";
$media-md: "(min-width:" + $md +") and (max-width:" + ($lg - 1) + ")";
$media-lg: "(min-width:" + $lg +") and (max-width:" + ($xlg - 1) + ")";
$media-xlg: "(min-width:" + $xlg +")";
$media-xxlg: "(min-width:" + $xxlg +")";


$iphone3:  "only screen and (max-height:380px)";
$iphone4:  "only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) and (orientation:portrait)";
$iphone5:  "only screen and (device-aspect-ratio: 40/71)";
$iphone6:  "only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)";

//Обязательно сначала xs потом самый старый iphone затем в порядке возрастания до 6
